// Importing necessary components and hooks
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Create = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        subdomain: '',
        isActive: false,
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.name) {
            formErrors.nameError = "The name cannot be null";
            isValid = false;
        }
        if (!formData.email) {
            formErrors.emailError = "The email must be valid";
            isValid = false;
        }
        if (!formData.phone) {
            formErrors.phoneError = "The phone number must be valid";
            isValid = false;
        }
        if (!formData.subdomain) {
            formErrors.subdomainError = "The subdomain cannot be null";
            isValid = false;
        }
        if (!formData.address) {
            formErrors.addressError = "The address cannot be null";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const accessToken = localStorage.getItem("access_token");
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/company`,
                    formData,
                    {
                        headers: {
                            Authorization: `${accessToken}`,
                        },
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        toast.success('Company successfully added!');
                        setTimeout(() => {
                            navigate('/company-list');
                        }, 1000);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        console.error('401 Error-------');
                        localStorage.clear();
                        navigate('/auth/login');
                    }
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.message || 'An error occurred. Please try again.');
                    } else {
                        console.error('There was an error creating the company!', error);
                    }
                });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Company Create
                                </MDTypography>
                            </MDBox>

                            <MDBox p={3}>
                                <form onSubmit={handleSubmit}>
                                    <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                                        <MDBox
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            width="100%"
                                            mr={2}
                                        >
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Name <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <MDInput
                                                    type="text"
                                                    fullWidth
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.nameError)}
                                                    sx={{
                                                        borderColor: errors.nameError ? 'red' : 'initial',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: errors.nameError ? 'red' : 'initial',
                                                        }
                                                    }}
                                                />
                                                {errors.nameError && (
                                                    <MDTypography variant="caption" color="error" fontWeight="light">
                                                        {errors.nameError}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                        <MDBox
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            width="100%"
                                            ml={2}
                                        >
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Email <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={1} width="100%">
                                                <MDInput
                                                    type="email"
                                                    fullWidth
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.emailError)}
                                                    sx={{
                                                        borderColor: errors.emailError ? 'red' : 'initial',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: errors.emailError ? 'red' : 'initial',
                                                        }
                                                    }}
                                                />
                                                {errors.emailError && (
                                                    <MDTypography variant="caption" color="error" fontWeight="light">
                                                        {errors.emailError}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                    <MDBox display="flex" flexDirection="column" mb={3}>
                                        <MDBox display="flex" flexDirection="row">
                                            <MDBox
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                width="100%"
                                                mr={2}
                                            >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                    Phone <span style={{ color: 'red' }}>*</span>
                                                </MDTypography>
                                                <MDBox mb={2} width="100%">
                                                    <MDInput
                                                        type="text"
                                                        fullWidth
                                                        name="phone"
                                                        value={formData.phone}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.phoneError)}
                                                        sx={{
                                                            borderColor: errors.phoneError ? 'red' : 'initial',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: errors.phoneError ? 'red' : 'initial',
                                                            }
                                                        }}
                                                    />
                                                    {errors.phoneError && (
                                                        <MDTypography variant="caption" color="error" fontWeight="light">
                                                            {errors.phoneError}
                                                        </MDTypography>
                                                    )}
                                                </MDBox>
                                            </MDBox>
                                            <MDBox
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                width="100%"
                                                ml={2}
                                            >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                    Address <span style={{ color: 'red' }}>*</span>
                                                </MDTypography>
                                                <MDBox mb={1} width="100%">
                                                    <MDInput
                                                        type="text"
                                                        fullWidth
                                                        name="address"
                                                        value={formData.address}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.addressError)}
                                                        sx={{
                                                            borderColor: errors.addressError ? 'red' : 'initial',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: errors.addressError ? 'red' : 'initial',
                                                            }
                                                        }}
                                                    />
                                                    {errors.addressError && (
                                                        <MDTypography variant="caption" color="error" fontWeight="light">
                                                            {errors.addressError}
                                                        </MDTypography>
                                                    )}
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="row" mt={3} mb={3}>
                                            <MDBox
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                width="100%"
                                                mr={2}
                                            >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                    Subdomain <span style={{ color: 'red' }}>*</span>
                                                </MDTypography>
                                                <MDBox mb={2} width="100%">
                                                    <MDInput
                                                        type="text"
                                                        fullWidth
                                                        name="subdomain"
                                                        value={formData.subdomain}
                                                        onChange={handleChange}
                                                        error={Boolean(errors.subdomainError)}
                                                        sx={{
                                                            borderColor: errors.subdomainError ? 'red' : 'initial',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: errors.subdomainError ? 'red' : 'initial',
                                                            }
                                                        }}
                                                    />
                                                    {errors.subdomainError && (
                                                        <MDTypography variant="caption" color="error" fontWeight="light">
                                                            {errors.subdomainError}
                                                        </MDTypography>
                                                    )}
                                                </MDBox>
                                            </MDBox>
                                            <MDBox
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                width="100%"
                                                ml={2}
                                                mt={5}
                                            >
                                                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                    Is Active
                                                </MDTypography>
                                                <MDBox ml={1}>
                                                    <input
                                                        type="checkbox"
                                                        name="isActive"
                                                        checked={formData.isActive}
                                                        onChange={handleChange}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={4} display="flex" justifyContent="end">
                                        <MDButton variant="gradient" color="info" type="submit">
                                            Create Company
                                        </MDButton>
                                    </MDBox>
                                </form>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
            <ToastContainer />
        </DashboardLayout>
    );
}

export default Create;
