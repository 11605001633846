import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import { Link, useNavigate } from "react-router-dom";
import BasicLayoutLanding from "layouts/authentication/components/BasicLayoutLanding";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import axios from "axios";

function ForgotPassword() {
  const [isDemo, setIsDemo] = useState(false);
  const [notification, setNotification] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [input, setEmail] = useState({
    email: "",
  });
  const [error, setError] = useState({
    err: false,
    textError: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsDemo(process.env.REACT_APP_IS_DEMO === "true");
  }, []);

  const changeHandler = (e) => {
    setEmail({
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (input.email.trim().length === 0) {
      setError({ err: true, textError: "Email is required" });
      return;
    }

    if (!input.email.trim().match(mailFormat)) {
      setError({ err: true, textError: "The email must be valid" });
      return;
    }

    const myData = {
      email: input.email,
      redirect_url: `${process.env.REACT_APP_URL}/auth/reset-password`,
    };

    try {
      if (!isDemo) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/forgot-password`,
          myData
        );
        setError({ err: false, textError: "" });
        setResponseMessage(response.data.message);

        if (response.status === 200) {
          setTimeout(() => {
            navigate("/auth/reset-password");
          }, 1500); // Redirect after 1.5 seconds
        }
      }
      setNotification(true);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        const apiErrorMessage = err.response.data.message || "An error occurred";
        setError({ err: true, textError: apiErrorMessage });
      } else {
        setError({ err: true, textError: "An error occurred" });
      }
    }
  };

  return (
    <BasicLayoutLanding image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="POST" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={input.email}
                name="email"
                onChange={changeHandler}
                error={error.err}
              />
            </MDBox>
            {error.err && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {error.textError}
              </MDTypography>
            )}
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                Reset
              </MDButton>
            </MDBox>
          </MDBox>
          {responseMessage && (
            <MDTypography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
              {responseMessage}
            </MDTypography>
          )}
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Sign In
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
      {notification && (
        <MDAlert color="info" mt="20px" dismissible>
          <MDTypography variant="body2" color="white">
            {isDemo
              ? "You can't update the password in the demo version"
              : "Please check your email to reset your password."}
          </MDTypography>
        </MDAlert>
      )}
    </BasicLayoutLanding>
  );
}

export default ForgotPassword;
