import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {useEffect, useState} from "react";
import axios from "axios";
import MDBadge from "components/MDBadge";
import {Link, useNavigate} from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import MDPagination from "components/MDPagination"; // Import MDPagination
import {toast, ToastContainer} from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SearchIcon from "@mui/icons-material/Search";
import {Input} from "@mui/material";

const Index = () => {
    const [companies, setCompanies] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10); // Number of companies per page
    const navigate = useNavigate();

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`, {
                headers: {
                    Authorization: `${accessToken}`,
                },
            });
            setCompanies(response.data);
            setAllCompanies(response.data);
        } catch (error) {
            if (error.response.status === 401) {
                console.error("401 Error-------");
                localStorage.clear();
                navigate("/auth/login");
            }
            console.error("There was an error fetching the companies!", error);
        }
    };

    const handleDelete = (id) => {
        const accessToken = localStorage.getItem("access_token");
        axios
            .delete(`${process.env.REACT_APP_API_URL}/company/${id}`, {
                headers: {
                    Authorization: `${accessToken}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setCompanies((prevCompanies) => prevCompanies.filter((company) => company._id !== id));
                    // Show success notification
                    toast.success("Company deleted successfully!");
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    console.error("401 Error-------");
                    localStorage.clear();
                    navigate("/auth/login");
                }
                console.error("There was an error deleting the company!", error);
                // Show error notification
                toast.error("Error deleting company! Please try again.");
            });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleToggle = async (id, currentStatus) => {
        try {
            const updatedCompany = { isActive: !currentStatus };
            const accessToken = localStorage.getItem("access_token");
            const response = await axios.put(
                `${process.env.REACT_APP_API_URL}/company/${id}`,
                updatedCompany,
                {
                    headers: {
                        Authorization: `${accessToken}`,
                    },
                }
            );

            if (response.status === 200) {
                setCompanies((prevCompanies) =>
                    prevCompanies.map((company) =>
                        company._id === id ? { ...company, isActive: !currentStatus } : company
                    )
                );

                toast.success(
                    `Company status changed to ${!currentStatus ? "Active" : "Inactive"}`
                );


            }
        } catch (error) {
            if (error.response.status === 401) {
                console.error("401 Error-------");
                localStorage.clear();
                navigate("/auth/login");
            }
            console.error("There was an error updating the user status!", error);
        }
    };

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentCompanies = companies.slice(indexOfFirstCompany, indexOfLastCompany);
    const totalPages = Math.ceil(companies.length / companiesPerPage);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Company Details
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <div className={"d-flex"}>
                                    <Input
                                        placeholder="Search company"
                                        onChange={(e) => {
                                            const searchValue = e.target.value;
                                            const filteredCompanies = allCompanies.filter((company) =>
                                                company.name.toLowerCase().includes(searchValue.toLowerCase())
                                            );
                                            setCompanies(filteredCompanies);
                                        }}
                                        startAdornment={<SearchIcon/>}
                                    />
                                </div>
                            </MDBox>
                            <MDBox pt={3}>
                                <div style={{paddingLeft: "15px", paddingRight: "15px"}}>
                                    {currentCompanies.length > 0 ? (
                                        <table style={{width: "100%", borderCollapse: "collapse"}}>
                                            <thead>
                                            <tr style={{backgroundColor: "#f0f2f5"}}>
                                                <th style={tableHeaderStyle}>Name</th>
                                                <th style={tableHeaderStyle}>Email</th>
                                                <th style={tableHeaderStyle}>Phone</th>
                                                <th style={tableHeaderStyle}>Status</th>
                                                <th style={tableHeaderStyle}>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {currentCompanies.map((company) => (
                                                <tr
                                                    key={company._id}
                                                    style={{borderBottom: "1px solid #e0e0e0"}}
                                                >
                                                    <td style={tableCellStyle}>{company.name}</td>
                                                    <td style={tableCellStyle}>{company.email}</td>
                                                    <td style={tableCellStyle}>{company.phone}</td>
                                                    <td style={tableCellStyle}>
                                                        <Switch
                                                            checked={company.isActive}
                                                            onChange={() =>
                                                                handleToggle(company._id, company.isActive)
                                                            }
                                                            color={company.isActive ? "primary" : "default"}
                                                        />
                                                    </td>
                                                    <td style={tableCellStyle}>
                                                        <MDBox display="flex" justifyContent="space-between">
                                                            <Tooltip title="Edit" arrow>
                                                                <Link
                                                                    to={`/company-edit/${company._id}`}
                                                                    style={{textDecoration: "none"}}
                                                                >
                                                                    <IconButton
                                                                        aria-label="edit"
                                                                        size="small"
                                                                        color="primary"
                                                                    >
                                                                        <EditIcon sx={{color: "orange"}}/>
                                                                    </IconButton>
                                                                </Link>
                                                            </Tooltip>
                                                        </MDBox>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <MDTypography variant="h6" color="info">
                                            No company found
                                        </MDTypography>
                                    )}
                                </div>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-between"
                                   style={{padding: "15px 15px 50px 15px"}}>
                                <div>
                                    <MDTypography variant="button" color="text">
                                        {`Showing ${indexOfFirstCompany + 1}-${Math.min(
                                            indexOfLastCompany,
                                            companies.length
                                        )} of ${companies.length} records | Page ${currentPage} of ${totalPages}`}
                                    </MDTypography>
                                </div>
                                <MDPagination>
                                    {Array.from({length: totalPages}, (_, index) => (
                                        <MDPagination
                                            key={index + 1}
                                            item
                                            active={index + 1 === currentPage}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </MDPagination>
                                    ))}
                                </MDPagination>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
            <ToastContainer/> {/* Add the ToastContainer here */}
        </DashboardLayout>
    );
};

// Custom styles for table headers and cells
const tableHeaderStyle = {
    padding: "12px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "14px",
    borderBottom: "1px solid #e0e0e0",
};

const tableCellStyle = {
    padding: "12px",
    textAlign: "left",
    fontSize: "14px",
    borderBottom: "1px solid #e0e0e0",
};

export default Index;
