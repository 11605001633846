// MUI material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// React and other dependencies
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Import react-toastify for notifications
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {InputAdornment} from "@mui/material";

const CreateUser = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        company_id: "",
        isActive: true,
    });

    const [companies, setCompanies] = useState([]);
    const [errors, setErrors] = useState({}); // State to handle form validation errors

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/company`,
                {
                    headers: {
                        Authorization: `${accessToken}`,
                    },
                }
            );
            // Filter companies to include only those that are active
            const activeCompanies = response.data.filter(company => company.isActive);
            setCompanies(activeCompanies);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('401 Error-------');
                localStorage.clear();
                navigate('/auth/login');
            }
            console.error("Error fetching companies!", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
        setErrors({ ...errors, [name]: null }); // Clear the specific error when the user types
    };

    const handleCheckboxChange = (e) => {
        setUser({
            ...user,
            isActive: e.target.checked,
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!user.username) newErrors.username = "Username is required";
        if (!user.email) newErrors.email = "Email is required";
        if (!user.password) newErrors.password = "Password is required";
        if (!user.company_id) newErrors.company_id = "Company selection is required";

        if (user.password !== user.confirmPassword)
            newErrors.confirmPassword = "Passwords do not match";

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors); // Set errors only if there are validation issues
            return;
        }

        try {
            const accessToken = localStorage.getItem("access_token");
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/inadminuserregister`,
                user,
                {
                    headers: {
                        Authorization: `${accessToken}`,
                    },
                }
            );
            toast.success("User Successfully Created!"); // Show success notification
            setTimeout(() => {
                navigate("/user-list");
            }, 1500); // Redirect after 1.5 seconds
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('401 Error-------');
                localStorage.clear();
                navigate('/auth/login');
            }
            toast.error(
                error.response?.data?.message || "An error occurred while creating the user."
            ); // Show error notification
        }
    };



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Create User
                                </MDTypography>
                            </MDBox>
                            <MDBox p={3}>
                                <form onSubmit={handleSubmit}>
                                    <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Username <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    name="username"
                                                    value={user.username}
                                                    onChange={handleInputChange}
                                                    error={Boolean(errors.username)} // Show error styling
                                                    sx={{
                                                        borderColor: errors.username ? 'red' : undefined, // Add red border if there's an error
                                                        "&:focus": {
                                                            borderColor: errors.username ? 'red' : undefined,
                                                        }
                                                    }}
                                                />
                                                {errors.username && (
                                                    <MDTypography variant="caption" color="error">
                                                        {errors.username}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Email <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={1} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="email"
                                                    name="email"
                                                    value={user.email}
                                                    onChange={handleInputChange}
                                                    error={Boolean(errors.email)} // Show error styling
                                                    sx={{
                                                        borderColor: errors.email ? 'red' : undefined, // Add red border if there's an error
                                                        "&:focus": {
                                                            borderColor: errors.email ? 'red' : undefined,
                                                        }
                                                    }}
                                                />
                                                {errors.email && (
                                                    <MDTypography variant="caption" color="error">
                                                        {errors.email}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                    <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Password <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="password"
                                                    name="password"
                                                    value={user.password}
                                                    onChange={handleInputChange}
                                                    error={Boolean(errors.password)} // Show error styling
                                                    sx={{
                                                        borderColor: errors.password ? 'red' : undefined, // Add red border if there's an error
                                                        "&:focus": {
                                                            borderColor: errors.password ? 'red' : undefined,
                                                        }
                                                    }}
                                                />
                                                {errors.password && (
                                                    <MDTypography variant="caption" color="error">
                                                        {errors.password}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                        {/*Write the confirm password*/}
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Confirm Password <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={user.confirmPassword}
                                                    onChange={handleInputChange}
                                                    error={Boolean(errors.confirmPassword)} // Show error styling
                                                    sx={{
                                                        borderColor: errors.confirmPassword ? 'red' : undefined, // Add red border if there's an error
                                                        "&:focus": {
                                                            borderColor: errors.confirmPassword ? 'red' : undefined,
                                                        }
                                                    }}
                                                />
                                                {errors.confirmPassword && (
                                                    <MDTypography variant="caption" color="error">
                                                        {errors.confirmPassword}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Company <span style={{ color: 'red' }}>*</span>
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <select
                                                    name="company_id"
                                                    value={user.company_id}
                                                    onChange={handleInputChange}
                                                    style={{
                                                        width: "100%",
                                                        padding: "8px",
                                                        borderRadius: "5px",
                                                        borderColor: errors.company_id ? 'red' : 'rgb(211 211 211)', // Set red border if error
                                                        height: "44px",
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        Select a company
                                                    </option>
                                                    {companies.map((company) => (
                                                        <option key={company._id} value={company._id}>
                                                            {company.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.company_id && (
                                                    <MDTypography variant="caption" color="error">
                                                        {errors.company_id}
                                                    </MDTypography>
                                                )}
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                    <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                First Name
                                            </MDTypography>
                                            <MDBox mb={2} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    name="firstName"
                                                    value={user.firstName}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBox>
                                        </MDBox>
                                        <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                                            <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                                Last Name
                                            </MDTypography>
                                            <MDBox mb={1} width="100%">
                                                <MDInput
                                                    fullWidth
                                                    type="text"
                                                    name="lastName"
                                                    value={user.lastName}
                                                    onChange={handleInputChange}
                                                />
                                            </MDBox>
                                        </MDBox>
                                    </MDBox>

                                    <MDBox display="flex" flexDirection="row" alignItems="center" width="100%" mt={3}>
                                        <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                                            Is Active
                                        </MDTypography>
                                        <MDBox ml={1}>
                                            <input
                                                type="checkbox"
                                                name="isActive"
                                                checked={user.isActive}
                                                onChange={handleCheckboxChange}
                                            />
                                        </MDBox>
                                    </MDBox>

                                    <MDBox mt={4} mb={1}>
                                        <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                            Create User
                                        </MDButton>
                                    </MDBox>
                                </form>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
            {/* ToastContainer to display the notifications */}
            <ToastContainer />
        </DashboardLayout>
    );
};

export default CreateUser;
