import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// Import react-toastify for notifications
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ChangeEmail = () => {
  const { id } = useParams(); // Get the user id from the route parameters
  const [formData, setFormData] = useState({
    currentEmail: '',
    newEmail: '',
    confirmNewEmail: '', // Add confirmNewEmail field
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data and set the current email
    const accessToken = localStorage.getItem("access_token");
    axios.get(
            `${process.env.REACT_APP_API_URL}/auth/get-user/${id}`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          )
      .then(response => {
        if (response.data) {
          setFormData(prevState => ({
            ...prevState,
            currentEmail: response.data.email, // Set the fetched email
          }));
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        }
        console.error('There was an error fetching the user data!', error);
      });
  }, [id, navigate]);

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.newEmail) {
      formErrors.newEmailError = "The new email cannot be null";
      isValid = false;
    }

    if (formData.newEmail !== formData.confirmNewEmail) {
      formErrors.confirmNewEmailError = "New Email and Confirm New Email do not match";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const accessToken = localStorage.getItem("access_token");
      axios.put(
        `${process.env.REACT_APP_API_URL}/auth/admin-update-user-email`,
        { currentEmail: formData.currentEmail, newEmail: formData.newEmail }, // Send both currentEmail and newEmail
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      )
        .then(response => {
          if (response.status === 200) {
            toast.success('Email successfully updated!'); // Success notification
            setTimeout(() => {
              navigate('/user-list'); // Navigate to /user-list after success
            }, 1000); // Redirect after 1 second
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            console.error('401 Error-------');
            localStorage.clear();
            navigate('/auth/login');
          }
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.message || 'An error occurred. Please try again.'); // Error notification
          } else {
            console.error('There was an error updating the email!', error);
          }
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Change User Email
                </MDTypography>
              </MDBox>

              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="column" mb={3}>
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Current Email <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="email"
                        fullWidth
                        name="currentEmail"
                        value={formData.currentEmail}
                        InputProps={{
                          readOnly: true, // Make the field read-only
                        }}
                      />
                    </MDBox>
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        New Email <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="email"
                        fullWidth
                        name="newEmail"
                        value={formData.newEmail}
                        onChange={handleChange}
                        error={Boolean(errors.newEmailError)}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.newEmailError ? 'red' : 'initial',
                          }
                        }}
                      />
                      {errors.newEmailError && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {errors.newEmailError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {/* Confirm New Email Field */}
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Confirm New Email <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDInput
                        type="email"
                        fullWidth
                        name="confirmNewEmail"
                        value={formData.confirmNewEmail}
                        onChange={handleChange}
                        error={Boolean(errors.confirmNewEmailError)}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.confirmNewEmailError ? 'red' : 'initial',
                          }
                        }}
                      />
                      {errors.confirmNewEmailError && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {errors.confirmNewEmailError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox mt={4} display="flex" justifyContent="end">
                    <MDButton variant="gradient" color="info" type="submit">
                      Change Email
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      {/* Add ToastContainer to render toast notifications */}
      <ToastContainer/>
    </DashboardLayout>
  );
};

export default ChangeEmail;
