import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailIcon from "@mui/icons-material/Mail";
import {Input} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Index = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [companies, setCompanies] = useState([]); // State to hold company data
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsersAndCompanies();
  }, []);

  const fetchUsersAndCompanies = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");

      // Fetch users
      const usersResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/users`,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );
      setUsers(usersResponse.data);
      setAllUsers(usersResponse.data);

      // Fetch companies
      const companiesResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/company`,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );

      setCompanies(companiesResponse.data); // Store companies in state
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("401 Error-------");
        localStorage.clear();
        navigate("/auth/login");
      }
      console.error("There was an error fetching data!", error);
    }
  };

  const getCompanyName = (company_id) => {
    const company = companies.find((company) => company._id === company_id);
    return company ? company.name : "Unknown"; // Fallback if company is not found
  };

  const handleDelete = (id) => {
    const accessToken = localStorage.getItem("access_token");
    axios
      .delete(`${process.env.REACT_APP_API_URL}/auth/delete-user/${id}`, {
        headers: {
          Authorization: `${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // Remove user from state
          setUsers((prevUsers) => prevUsers.filter((user) => user._id !== id));
          // Show success notification
          toast.success("User deleted successfully!");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.error("401 Error-------");
          localStorage.clear();
          navigate("/auth/login");
        }
        console.error("There was an error deleting the user!", error);
        // Show error notification
        toast.error("Error deleting user! Please try again.");
      });
  };

  const handleToggle = async (id, currentStatus) => {
    try {
      const updatedUser = { isActive: !currentStatus };
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/update-user/${id}`,
        updatedUser,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === id ? { ...user, isActive: !currentStatus } : user
          )
        );

        toast.success(
          `User status changed to ${!currentStatus ? "Active" : "Inactive"}`
        );
      }
    } catch (error) {
      if (error.response.status === 401) {
        console.error("401 Error-------");
        localStorage.clear();
        navigate("/auth/login");
      }
      console.error("There was an error updating the user status!", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(users.length / usersPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User Details
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <div className={"d-flex"}>
                  <Input
                      placeholder="Search company"
                      onChange={(e) => {
                        const searchQuery = e.target.value;
                        const filteredUsers = allUsers.filter((user) => {
                          return user.username.toLowerCase().includes(searchQuery.toLowerCase())
                              || user.email.toLowerCase().includes(searchQuery.toLowerCase())
                              || getCompanyName(user.company_id).toLowerCase().includes(searchQuery.toLowerCase());
                        });
                        setUsers(filteredUsers);
                      }}
                      startAdornment={<SearchIcon/>}
                  />
                </div>
              </MDBox>
              <MDBox pt={3}>
                <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  {currentUsers.length > 0 ? (
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <thead>
                        <tr style={{ backgroundColor: "#f0f2f5" }}>
                          <th style={tableHeaderStyle}>Username</th>
                          <th style={tableHeaderStyle}>Email</th>
                          <th style={tableHeaderStyle}>Company Name</th>
                          <th style={tableHeaderStyle}>Status</th>
                          <th style={tableHeaderStyle}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map((user) => (
                          <tr
                            key={user._id}
                            style={{ borderBottom: "1px solid #e0e0e0" }}
                          >
                            <td style={tableCellStyle}>{user.username}</td>
                            <td style={tableCellStyle}>{user.email}</td>
                            <td style={tableCellStyle}>
                              {getCompanyName(user.company_id)}
                            </td>
                            <td style={tableCellStyle}>
                              <Switch
                                checked={user.isActive}
                                onChange={() =>
                                  handleToggle(user._id, user.isActive)
                                }
                                color={user.isActive ? "primary" : "default"}
                              />
                            </td>
                            <td style={tableCellStyle}>
                              <MDBox display="flex" justifyContent="space-between">
                                <Tooltip title="Edit" arrow>
                                  <Link
                                    to={`/user-edit/${user._id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <IconButton
                                      aria-label="edit"
                                      size="small"
                                      color="primary"
                                    >
                                      <EditIcon sx={{ color: "orange" }} />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                                <Tooltip title="Change Password" arrow>
                                  <Link
                                    to={`/user-change-password/${user._id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <IconButton
                                      aria-label="change password"
                                      size="small"
                                      color="secondary"
                                    >
                                      <LockOpenIcon sx={{ color: "blue" }} />
                                    </IconButton>
                                  </Link>
                                </Tooltip>

                                <Tooltip title="Change Email" arrow>
                                  <Link
                                    to={`/user-change-email/${user._id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <IconButton
                                      aria-label="change email"
                                      size="small"
                                      color="secondary"
                                    >
                                      <MailIcon sx={{ color: "green" }} />
                                    </IconButton>
                                  </Link>
                                </Tooltip>
                                
                                <Tooltip title="Delete" arrow>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    color="error"
                                    onClick={() => handleDelete(user._id)}
                                  >
                                    <DeleteIcon sx={{ color: "red" }} />
                                  </IconButton>
                                </Tooltip>
                              </MDBox>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <MDTypography variant="h6" color="info">
                      No users found
                    </MDTypography>
                  )}
                </div>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between" style={{ padding: "15px 15px 50px 15px" }}>
                <div>
                  <MDTypography variant="button" color="text">
                    {`Showing ${indexOfFirstUser + 1}-${Math.min(
                      indexOfLastUser,
                      users.length
                    )} of ${users.length} records | Page ${currentPage} of ${totalPages}`}
                  </MDTypography>
                </div>
                <MDPagination>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <MDPagination
                      key={index + 1}
                      item
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </MDPagination>
                  ))}
                </MDPagination>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
};

// Custom styles for table headers and cells
const tableHeaderStyle = {
  padding: "12px",
  textAlign: "left",
  fontWeight: "bold",
  fontSize: "14px",
  borderBottom: "1px solid #e0e0e0",
};

const tableCellStyle = {
  padding: "12px",
  textAlign: "left",
  fontSize: "14px",
  borderBottom: "1px solid #e0e0e0",
};

export default Index;
