import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { TextField, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import MDButton from "components/MDButton";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import styles

function ChangePassword() {
  const [formData, setFormData] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const navigate = useNavigate(); // Hook to handle navigation

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-admin-detail`, {
            token: token // Pass the token in the request body
          });

          const userDetail = response.data.user;
          setFormData(prevState => ({
            ...prevState,
            email: userDetail.email
          }));
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.error('401 Error-------');
            localStorage.clear();
            navigate('/auth/login');
          }
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Clear error messages on input change
    if (name === "oldPassword") {
      setOldPasswordError("");
    } else if (name === "newPassword") {
      setNewPasswordError("");
    }

    if (name === "confirmPassword") {
        setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage("");
    setOldPasswordError("");
    setNewPasswordError("");

    // Validate the form
    let isValid = true;
    if (!formData.oldPassword) {
      setOldPasswordError("Old password is required");
      isValid = false;
    }
    if (!formData.newPassword) {
      setNewPasswordError("New password is required");
      isValid = false;
    }

    if (!formData.confirmPassword) {
      setConfirmPasswordError("New password is required");
      isValid = false;
    }

    if (formData.newPassword !== formData.confirmPassword) {
        setConfirmPasswordError("Passwords do not match");
        isValid = false;
    }

    if (!isValid) {
      setLoading(false);
      return; // Stop submission if validation fails
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/reset-password`,
        formData,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );
      setResponseMessage(response.data.message);

      if (response.status === 200) { // Check if HTTP status is 200 OK
        toast.success('Password changed successfully!'); // Show success notification
        setTimeout(() => {
          navigate("/profile"); // Redirect to the profile page after 3 seconds
        }, 1500); // Redirect after 1.5 seconds
      }
    } catch (error) {
      setResponseMessage("Error resetting password");
      toast.error("Old Password is Wrong"); // Show error notification
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ padding: 2 }}>
        <Header>
          <Typography variant="h5" gutterBottom>
            Change Password
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label={<span>Email <span style={{ color: 'red' }}>*</span></span>}
                  name="email"
                  value={formData.email}
                  fullWidth
                  InputProps={{
                    readOnly: true, // Make the field read-only
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={<span>Old Password <span style={{ color: 'red' }}>*</span></span>}
                  name="oldPassword"
                  type="password"
                  value={formData.oldPassword}
                  onChange={handleChange}
                  fullWidth
                  error={Boolean(oldPasswordError)} // Show error state if there's an error
                  helperText={oldPasswordError} // Show error message
                  sx={{
                    borderColor: oldPasswordError ? 'red' : 'inherit', // Change border color to red if there's an error
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={<span>New Password <span style={{ color: 'red' }}>*</span></span>}
                  name="newPassword"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleChange}
                  fullWidth
                  error={Boolean(newPasswordError)} // Show error state if there's an error
                  helperText={newPasswordError} // Show error message
                  sx={{
                    borderColor: newPasswordError ? 'red' : 'inherit', // Change border color to red if there's an error
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    label={<span>Confirm Password <span style={{ color: 'red' }}>*</span></span>}
                    name="confirmPassword"
                    type="password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(confirmPasswordError)} // Show error state if there's an error
                    helperText={confirmPasswordError} // Show error message
                    sx={{
                      borderColor: confirmPasswordError ? 'red' : 'inherit', // Change border color to red if there's an error
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton type="submit" variant="contained" color="success" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </MDButton>
              </Grid>
            </Grid>
          </form>
          {responseMessage && (
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
              {responseMessage}
            </Typography>
          )}
        </Header>
      </Box>
      <Footer />
      <ToastContainer /> {/* Include ToastContainer to display notifications */}
    </DashboardLayout>
  );
}

export default ChangePassword;
