// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// React and other dependencies
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// Import toast functions and CSS for toast notifications
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateUser = () => {
  const { id } = useParams(); // Get the user ID from the URL parameters
  const navigate = useNavigate(); // To navigate after update
  const [user, setUser] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    company_id: "", // Change company_id to company_id
    isActive: false,
  });

  const [companies, setCompanies] = useState([]); // State to store companies
  const [errors, setErrors] = useState({}); // State to handle form validation errors

  useEffect(() => {
    fetchUser();
    fetchCompanies(); // Fetch companies when the component mounts
  }, []);

  const fetchUser = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/get-user/${id}`,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );
      setUser(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("401 Error-------");
        localStorage.clear();
        navigate("/auth/login");
      }
      console.error("There was an error fetching the user data!", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`, {
        headers: {
          Authorization: `${accessToken}`,
        },
      });
      setCompanies(response.data); // Store companies in state
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("401 Error-------");
        localStorage.clear();
        navigate("/auth/login");
      }
      console.error("There was an error fetching the companies data!", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setUser({
      ...user,
      isActive: e.target.checked,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!user.username) newErrors.username = "Username is required";
    if (!user.email) newErrors.email = "Email is required";
    if (!user.company_id) newErrors.company_id = "Company selection is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/auth/update-user/${id}`,
        user,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );

      // Show success toast notification
      if (response.status === 200) {
        toast.success("User updated successfully!", {
          position: "top-right",
          autoClose: 2000,
        });
      }

      // Redirect after 1.5 seconds
      setTimeout(() => {
        navigate("/user-list");
      }, 1500);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("401 Error-------");
        localStorage.clear();
        navigate("/auth/login");
      }

      // Show error toast notification
      const errorMessage =
        error.response?.data?.message || "There was an error updating the user.";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 3000,
      });

      console.error("There was an error updating the user!", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Update User
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Username <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="username"
                          value={user.username}
                          onChange={handleInputChange}
                          error={Boolean(errors.username)} // Dynamic error state
                          sx={{
                            borderColor: errors.username ? 'red' : undefined, // Conditional border color
                          }}
                        />
                        {errors.username && (
                          <MDTypography variant="caption" color="error">
                            {errors.username}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Email <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="email"
                          name="email"
                          value={user.email}
                          InputProps={{
                            readOnly: true, // Make the field read-only
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        First Name
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="firstName"
                          value={user.firstName}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" ml={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Last Name
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="lastName"
                          value={user.lastName}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" width="50%" mt={5} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Company <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <select
                          name="company_id"
                          value={user.company_id}
                          onChange={handleInputChange}
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "5px",
                            borderColor: errors.company_id ? 'red' : 'rgb(211 211 211)', // Conditional border color
                            height: "44px",
                          }}
                        >
                          <option value="" disabled>
                            Select a company
                          </option>
                          {companies
                            .filter(company => company.isActive) // Filter out inactive companies
                            .map(company => (
                              <option key={company._id} value={company._id}>
                                {company.name}
                              </option>
                            ))}
                        </select>
                        {errors.company_id && (
                          <MDTypography variant="caption" color="error">
                            {errors.company_id}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    mt={3}
                  >
                    <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                      Is Active
                    </MDTypography>
                    <MDBox ml={1}>
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={user.isActive}
                        onChange={handleCheckboxChange}
                      />
                    </MDBox>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" fullWidth>
                      Update User
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <ToastContainer />
    </DashboardLayout>
  );
};

export default UpdateUser;
