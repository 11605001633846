import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const CompanyUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    name: "",
    email: "",
    phone: "",
    address: "", 
    subdomain: "",
    isActive: false,
  });
  const [errors, setErrors] = useState({}); // State for storing validation errors

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/company/${id}`,
        {
          headers: {
            Authorization: `${accessToken}`,
          },
        }
      );
      setCompany(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error("Company Name Already Exists", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!company.name) {
      formErrors.nameError = "The name cannot be null";
      isValid = false;
    }
    if (!company.email) {
      formErrors.emailError = "The email cannot be null";  // Check if email is empty
      isValid = false;
    } else {
      // Simple email regex for validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(company.email)) {
        formErrors.emailError = "Please enter a valid email address"; // Check for valid email format
        isValid = false;
      }
    }
    if (!company.phone) {
      formErrors.phoneError = "The phone number cannot be null";
      isValid = false;
    }
    if (!company.address) {
      formErrors.addressError = "The address cannot be null";
      isValid = false;
    }
    if (!company.subdomain) {
      formErrors.subdomainError = "The subdomain cannot be null";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const accessToken = localStorage.getItem("access_token");
        await axios.put(
          `${process.env.REACT_APP_API_URL}/company/${id}`,
          company,
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        );
        toast.success('Company updated successfully!');
        setTimeout(() => {
          navigate("/company-list");
        }, 1500);
      } catch (error) {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        } else if (error.response.status === 400) {
          // Check for specific error message about name conflict
          toast.error(error.response.data.message || "There was an error updating the company!");
        } else {
          toast.error("There was an error updating the company!");
        }
        console.error("There was an error updating the company!", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edit Company
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      mr={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Name <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="name"
                          value={company.name}
                          onChange={handleInputChange}
                          error={Boolean(errors.nameError)}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.nameError ? 'red' : 'initial',
                            }
                          }}
                        />
                        {errors.nameError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            {errors.nameError}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Email <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="email"
                          name="email"
                          value={company.email}
                          onChange={handleInputChange} // Add this line to allow changes
                          error={Boolean(errors.emailError)} // Update to check for email error
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.emailError ? 'red' : 'initial',
                            }
                          }}
                        />
                        {errors.emailError && ( // Add email error message
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            {errors.emailError}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      mr={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Phone <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="phone"
                          value={company.phone}
                          onChange={handleInputChange}
                          error={Boolean(errors.phoneError)}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.phoneError ? 'red' : 'initial',
                            }
                          }}
                        />
                        {errors.phoneError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            {errors.phoneError}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Address <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="address"
                          value={company.address}
                          onChange={handleInputChange}
                          error={Boolean(errors.addressError)}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.addressError ? 'red' : 'initial',
                            }
                          }}
                        />
                        {errors.addressError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            {errors.addressError}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={3} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Subdomain <span style={{ color: 'red' }}>*</span>
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="subdomain"
                          value={company.subdomain}
                          onChange={() => {}} // Keep read-only
                          InputProps={{
                            readOnly: true,
                          }}
                          error={Boolean(errors.subdomainError)}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.subdomainError ? 'red' : 'initial',
                            }
                          }}
                        />
                        {errors.subdomainError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            {errors.subdomainError}
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      ml={2}
                      mt={5}
                    >
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Is Active
                      </MDTypography>
                      <MDBox ml={1}>
                        <label>
                          <input
                            type="checkbox"
                            name="isActive"
                            checked={company.isActive}
                            onChange={handleCheckboxChange}
                          />
                        </label>
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Update Company
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
};

export default CompanyUpdate;
